<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">Ubicación de tarjeta {{ card.tokens.bac }}</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row v-if="loading">
            <div class="ma-5" style="position: relative">
              <div
                style="
                  position: absolute;
                  z-index: 999;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader height="94" type="card-heading">
              </v-skeleton-loader>

              <v-skeleton-loader
                ref="skeleton"
                type="image"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </v-row>
          <v-row v-if="!loading">
            <v-col md="12" cols="12" sm="12">
              <l-map
                class="fill-height"
                style="height: 50vh"
                :zoom="zoom"
                :center="center"
              >
                <l-tile-layer :url="url"></l-tile-layer>
                <l-marker :lat-lng="[center[0], center[1]]" :icon="getImage">
                </l-marker>
              </l-map>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="6">
              <h2>Latitud</h2>
              <input
                :disabled="true"
                type="number"
                class="control-input control-input-number"
                placeholder="Latitud"
                v-model="center[0]"
              />
            </v-col>

            <v-col cols="12" xs="6" sm="6" md="6">
              <h2>Longitud</h2>
              <input
                :disabled="true"
                type="number"
                class="control-input control-input-number"
                placeholder="Longitud"
                v-model="center[1]"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-container fluid>
          <v-divider></v-divider>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" xs="12" sm="12" md="12">
              <v-row align="center" justify="end">
                <v-btn @click="$emit('cancel')" class="save-btn" color="primary"
                  >Cerrar</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapActions, mapState } from "vuex";

import {
  LMap,
  LTileLayer,
  LIcon,
  LMarker,
  LPopup,
  LTooltip,
} from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import VGeosearch from "vue2-leaflet-geosearch";

export default {
  name: "card-location",
  props: ["card"],
  components: {
    lottie: Lottie,
    LMap,
    LTileLayer,
    LIcon,
    LMarker,
    LPopup,
    LTooltip,
    VGeosearch,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },

      snackbar: false,
      snackbarText: "",
      saving: false,
      loading: true,
      geosearchOptions: {
        showMarker: false,
        provider: new OpenStreetMapProvider(),
        animateZoom: true,
        autoClose: true,
        searchLabel: "Buscar comercio",
      },
      business: null,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 0,
      center: [47.41322, -1.219482],
      staticAnchor: [16, 37],
      iconSize: 32,
      icon: L.icon(this.getImage),
    };
  },

  watch: {
    zoom() {
      if (this.zoom < 28) {
        setTimeout(() => {
          this.zoom += 5;
        }, 10);
      }
    },
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1];
    },

    getImage() {
      return L.icon({
        iconUrl: require("../../../assets/user.svg"),
        shadowUrl: require("../../../assets/map-marker.png"),
        iconSize: [32, 32],
        shadowSize: [64, 64],
        iconAnchor: [-12, 56],
        shadowAnchor: [4, 62],
        popupAnchor: [-3, -76],
      });
    },
  },

  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    // positionUpdated(e) {
    //       this.center = [e.lat, e.lng]
    // },

    // centerUpdated(e) {
    //         this.center = [this.card.location[0], this.card.location[1]]
    // },
  },
  mounted() {
    this.center = [this.card.location[0], this.card.location[1]];
    this.zoom = 1;
    this.loading = false;
  },
};
</script>
<template>
  <v-card class="py-1">
    <v-card-title class="headline">
      Estadísticas
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        small
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text style="height: auto; overflow-x: hidden">
      <v-row class="mt-5">
        <v-col cols="12" sm="4" md="4">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value="localDate"
            persistent
            scrollable
            max-width="300px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="localDate"
                label="Filtrar por fecha"
                prepend-icon="fa-calendar-alt"
                readonly
                filled
                rounded
                hide-details
                :disabled="loading"
                v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="localDate"
              range
              :title-date-format="title"
              scrollable
              :max="today"
            >
              <v-row no-gutters style="flex-wrap: nowrap">
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  rounded
                  outlined
                  color="primary"
                  @click="modal = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  class="flex-grow-1 flex-shrink-0 ml-2"
                  rounded
                  color="primary"
                  @click="
                    $refs.dialog.save(localDate);
                    updateDateFilter();
                  "
                >
                  Aceptar
                </v-btn>
              </v-row>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="localTagsToSearch"
            :items="searchableTags"
            prepend-icon="fa-filter"
            label="Filtrar por estado"
            item-text="name"
            item-value="name"
            multiple
            :loading="loading"
            aria-autocomplete="false"
            autocomplete="off"
            hide-details
            filled
            rounded
            :allow-overflow="false"
            clearable
          >
            <template v-slot:selection="data">
              <v-list-item-avatar
                :color="data.item.color"
                size="8"
                class="ma-1"
              >
                <span class="white--text headline"></span>
              </v-list-item-avatar>
            </template>
            <template v-slot:item="data">
              <template v-if="checkNotObject(data)">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar
                  :color="data.item.color"
                  size="8"
                  class="mr-2"
                >
                  <span class="white--text headline"></span>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ data.item.name | statusFilter }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="selectedCity"
            :items="cities"
            prepend-icon="fa-city"
            label="Filtrar por ciudad"
            multiple
            item-text="name"
            item-value=".key"
            aria-autocomplete="false"
            hide-details
            :loading="loading"
            filled
            rounded
            :allow-overflow="false"
            clearable
          >
          </v-select>
        </v-col>

        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="selectedBusiness"
            :items="sortedArray"
            chips
            prepend-icon="fa-building"
            label="Filtrar por comercio"
            item-text="shortName"
            item-value=".key"
            multiple
            aria-autocomplete="false"
            hide-details
            :loading="loading"
            filled
            rounded
            :allow-overflow="false"
            clearable
          >
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.shortName"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    style="color: #FF1744"
                    v-html="data.item.cityName"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" md="4">
          <v-select
            :items="sortedCategories"
            rounded
            v-model="selectedCategories"
            item-text="name"
            item-value=".key"
            filled
            clearable
            label="Seleccionar categoría"
          >
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.name"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    style="color: #FF1744"
                    v-html="data.item.cityName"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-select>
        </v-col>

        <v-col cols="12" sm="4" md="4">
          <v-btn
            color="primary"
            @click="filterData"
            :loading="loading"
            height="50"
            block
          >
            Filtrar
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col cols="12" sm="4" md="4">
          <div class="chart" dark height="150">
            <i class="fas fa-sack-dollar"></i>
            <div>
              <p>Total original de órdenes</p>

              <h2>{{ sumOriginalTotal() | formatNumber }}</h2>
            </div>
          </div>
        </v-col>

        <v-col cols="12" sm="4" md="4">
          <div class="chart" dark height="150">
            <i class="fas fa-sack-dollar"></i>
            <div>
              <p>Total órdenes</p>

              <h2>{{ sumField("orderTotal") | formatNumber }}</h2>
            </div>
          </div>
        </v-col>

        <v-col cols="12" sm="4" md="4">
          <div class="chart" dark height="150">
            <i class="fas fa-sack-dollar"></i>
            <div>
              <p>Total original productos</p>

              <h2>{{ sumOriginalProducts() | formatNumber }}</h2>
            </div>
          </div>
        </v-col>

        <v-col cols="12" sm="4" md="4">
          <div class="chart" dark height="150">
            <i class="fas fa-sack-dollar"></i>
            <div>
              <p>Total Promedio</p>
              <h2>
                {{
                  sumField("orderTotal") / allOrders.length > 0
                    ? (sumField("orderTotal") / allOrders.length).toFixed(2)
                    : 0 | formatNumber
                }}
              </h2>
            </div>
          </div>
        </v-col>

        <v-col cols="12" sm="4" md="4">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on" class="chart" dark height="150">
                <i class="fas fa-clock"></i>
                <div>
                  <p>Tiempo Total</p>
                  <h2>{{ totalOrderTime | toHours }}</h2>
                </div>
              </div>
            </template>
            <span
              >Sumatoria del tiempo desde que el cliente coloco una orden hasta
              que se le entregó.</span
            >
          </v-tooltip>
        </v-col>

        <v-col cols="12" sm="4" md="4">
          <div class="chart" dark height="150">
            <i class="fas fa-clipboard-list"></i>
            <div>
              <p>Total de órdenes</p>
              <h2>
                {{ allOrders.length }}
              </h2>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { db } from "@/firebase";
import { mapState } from "vuex";

export default {
  name: "charts",
  props: [
    // "data",
    "totalOrderTime",
    "errands",
    "orders",
    "cities",
    "date",
    "tagsToSearch",
  ],
  data() {
    return {
      modal: false,
      // date: [],
      allOrders: [],
      localTagsToSearch: "",
      localDate: [],
      loading: true,
      selectedCity: [],
      localErrands: [],
      selectedBusiness: null,
      localOrders: [],
      categories: [],
      selectedCategories: [],
      businesses: [],
      searchableTags: [
        { name: "placed", color: "lime darken-1" },
        { name: "accepted", color: "green" },
        { name: "enRoute", color: "amber" },
        { name: "onDelivery", color: "orange" },
        { name: "delivered", color: "cyan" },
        { name: "completed", color: "blue" },
        { name: "canceled", color: "grey" },
        { name: "pendingValidation", color: "purple" },
        { name: "rejected", color: "red" },
      ],
    };
  },

  filters: {
    toHours(seconds) {
      var duration = moment.duration(seconds, "seconds");
      var formatted = duration.format("hh:mm:ss");
      return formatted;
    },
    formatNumber: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },

    statusFilter: function (status) {
      switch (status) {
        case "placed":
          return "Colocada por el Cliente";
        case "accepted":
          return "Aceptada por el Comercio";
        case "enRoute":
          return "Chavo en Camino al Comercio";
        case "onDelivery":
          return "Chavo en Camino al Cliente";
        case "delivered":
          return "Entregada al Cliente";
        case "completed":
          return "Orden Completada";
        case "canceled":
          return "Cancelado por Cliente";
        case "pendingValidation":
          return "Requiere Validación";
        case "rejected":
          return "Cancelado por administración";
        default:
          return "Desconocido";
      }
    },
  },

  computed: {
    ...mapState(["selectedCities"]),

    sortedCategories() {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      return this.categories.sort(compare);
    },
    // airTimeOrders() {
    //   let total = this.allOrders.filter((e) => e.orderType == "airTime");
    //   return total.length || 0;
    // },
    today() {
      return new Date().toISOString().substr(0, 10);
    },

    sortedArray() {
      function compare(a, b) {
        if (a.shortName < b.shortName) return -1;
        if (a.shortName > b.shortName) return 1;
        return 0;
      }

      return this.businesses.sort(compare);
    },
  },

  methods: {
    sumField(key) {
      return this.allOrders.reduce((a, b) => a + (b[key] || 0), 0);
    },
    sumOriginalTotal() {
      return this.allOrders.reduce(
        (a, b) =>
          a + (b.orderTotal + (b.couponDiscount ? b.couponDiscount : 0) || 0),
        0
      );
    },

    sumOriginalProducts() {
      // let orderSubTotal = _order.originalProductsSubTotal
      //           ? _order.originalProductsSubTotal
      //           : _order.productsSubTotal;
      return this.allOrders.reduce(
        (a, b) =>
          a +
          (b.originalProductsSubTotal
            ? b.originalProductsSubTotal
            : b.productsSubTotal || 0),
        0
      );
    },
    async filterData() {
      let ordersCollection = db.collection(`orders`);
      let errandsCollection = db.collection(`ordersErrands`);

      this.loading = true;

      let ordersQuery = ordersCollection
        .where("cityId", "in", this.selectedCity)
        .where(
          "progressPlacedAt",
          ">",
          moment(this.localDate[0], "YYYY-MM-DD").startOf("day").toDate()
        )
        .where(
          "progressPlacedAt",
          "<",
          moment(this.localDate[1], "YYYY-MM-DD").endOf("day").toDate()
        );

      let errandsQuery = errandsCollection
        .where("cityId", "in", this.selectedCity)
        .where(
          "progressPlacedAt",
          ">",
          moment(this.localDate[0], "YYYY-MM-DD").startOf("day").toDate()
        )
        .where(
          "progressPlacedAt",
          "<",
          moment(this.localDate[1], "YYYY-MM-DD").endOf("day").toDate()
        );

      await this.$binding("localErrands", errandsQuery);
      await this.$binding("localOrders", ordersQuery);

      console.log(this.selectedBusiness);

      if (this.selectedBusiness && this.selectedBusiness.length) {
        this.localErrands = [];
      }

      let cargoCategories = {
        OBTiV1PEJckU8WRPXyvz: "VaSsfy0BLXQMsNCwzQoV",
        s6lHycg9FGizRa4H4eiG: "mEEVlUjvTEK253seCD0G",
      };
      // order.orderChannel == 'ocho_cargo'

      this.localErrands.forEach((e) => {
        if (e.orderChannel == "ocho_cargo") {
          e.categoryId = cargoCategories[e.cityId];
        }
      });

      let items = [...this.localOrders, ...this.localErrands];

      items = items.filter(
        (item) =>
          !this.localTagsToSearch ||
          !this.localTagsToSearch.length ||
          this.localTagsToSearch.includes(item.progressCurrentStatus)
      );

      // this.localOrders = this.localOrders.filter(
      //   (item) =>
      //     !this.localTagsToSearch.length ||
      //     this.localTagsToSearch.includes(item.progressCurrentStatus)
      // );

      items = items.filter(
        (item) =>
          !this.selectedCategories ||
          !this.selectedCategories.length ||
          this.selectedCategories.includes(item.categoryId)
      );

      items = items.filter(
        (item) =>
          !this.selectedBusiness ||
          !this.selectedBusiness.length ||
          this.selectedBusiness.includes(item.businessId)
      );

      this.allOrders = [...items];

      this.loading = false;
    },
    checkNotObject(data) {
      return typeof data.item !== "object";
    },
    title() {
      return "Selecciona las fechas a filtrar";
    },
    updateDateFilter() {
      if (this.localDate.length > 1) {
        if (
          moment(this.localDate[0], "YYYY-MM-DD") > new Date(this.localDate[1])
        ) {
          this.localDate = [this.localDate[1], this.localDate[0]];
        }
      } else {
        this.localDate = [this.localDate[0], this.localDate[0]];
      }
    },
  },

  async mounted() {
    this.selectedCity = this.selectedCities;
    this.localTagsToSearch = this.tagsToSearch;
    this.localDate = this.date;
    this.localOrders = Object.assign([], this.orders);
    this.localErrands = Object.assign([], this.errands);

    await this.$binding(
      "businesses",
      db
        .collection("businesses")
        .where("deleted", "==", false)
        .where("active", "==", true)
    );

    await this.$binding(
      "categories",
      db
        .collection("categories")
        .where("deleted", "==", false)
        .where("active", "==", true)
    );

    this.categories.forEach((category) => {
      let city = this.cities.find((e) => e[".key"] == category.city);
      category.cityName = city ? city.name : "";
    });

    this.businesses = this.businesses.map((business) => {
      let city = this.cities.find(
        (e) => business.cities && business.cities.includes(e[".key"])
      );
      business.cityName = city ? city.name : "";

      return business;
    });

    this.loading = false;

    this.allOrders = [...this.orders, ...this.errands];
  },
};
</script>


<style lang="scss" scoped>
.chart {
  background-color: #fafafa;
  height: 100px;
  border-radius: 10px;
  border: 1px solid #FF1744;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 15% 80%;
  padding: 10px;
  margin: 5px;
  p {
    // color: white;
    font-weight: bold;
    font-size: 18px;
  }

  h2 {
    // color: white;
    font-weight: bold;
  }

  i {
    font-size: 40px;
    color: #FF1744;
  }

  div {
    display: grid;
    justify-content: center;
  }
}
</style>
<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-card class="py-1 pa-3">
      <v-card-title>
        Cobro/Reembolso de la orden <b>{{ item.shortId }}</b>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row align="center" class="mx-0 ma-0 pa-0">
          <v-col cols="12" sm="12" md="12">
            <v-btn
              @click="extraDialog = true"
              :loading="loading"
              v-if="['sudo', 'admin', 'AF'].includes(user.type)"
              rounded
              color="primary"
              filled
            >
              <v-icon class="mr-3">fas fa-plus</v-icon>
              Nuevo Cobro/Reversión</v-btn
            >
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
              :headers="headers"
              :items="order.paymentModifications"
              :items-per-page="10"
              :loading="loading"
              v-if="!loading"
              sort-by="date"
              :sort-desc="true"
              :footer-props="{
                itemsPerPageOptions: [10, 50, 100],
              }"
            >
              <template v-slot:[`item.date`]="{ item }">
                {{ item.date | filterDate }}
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                {{ item.amount | currency }}
              </template>
              <template v-slot:[`item.transactionType`]="{ item }">
                {{ item.transactionType | filterTransaction }}
              </template></v-data-table
            >
          </v-col>
        </v-row>
      </v-card-text>

      <!-- <v-card-actions>
        <v-row align="center" class="d-flex mb-4 mx-4">
          <v-btn
            rounded
            class="flex-grow-1"
            large
            :elevation="0"
            :minHeight="56"
            :loading="loading"
            :disabled="loading"
            @click="$emit('cancel')"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            rounded
            class="flex-grow-1 ml-2"
            large
            :elevation="0"
            @click="next"
            :minHeight="56"
            :loading="loading"
            :disabled="loading"
          >
            Aceptar
          </v-btn>
        </v-row>
      </v-card-actions> -->
    </v-card>
    <v-dialog
      max-width="900"
      v-if="extraDialog"
      persistent
      v-model="extraDialog"
    >
      <add-extra-charge
        :item="order"
        @success="extraChargeCompleted"
        @cancel="extraDialog = false"
      ></add-extra-charge>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapState } from "vuex";

import addExtraCharge from "../c19/addExtraCharge";
import { db } from "@/firebase";

export default {
  name: "extra-charge",
  props: ["item"],
  components: {
    addExtraCharge,
  },
  data() {
    return {
      loading: true,
      snackbarText: "",
      extraDialog: false,
      snackbar: false,
      headers: [
        { text: "Descripción", value: "description" },
        { text: "Monto", value: "amount" },
        { text: "Fecha", value: "date" },
        { text: "Tipo", value: "transactionType" },
      ],
      order: null,
    };
  },

  filters: {
    filterDate(e) {
      return moment(e.toDate()).format("DD/MM/YYYY hh:mm A");
    },
    filterTransaction(e) {
      let transactions = {
        refund: "Reversión",
        payment: "Cobro Extra",
      };

      return transactions[e];
    },
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },
  },

  computed: {
    ...mapState(["user"]),
  },

  methods: {
    extraChargeCompleted() {
      this.snackbarText = "Transacción realizada correctamente.";
      this.snackbar = true;
      this.extraDialog = false;
    },
  },

  async mounted() {
    await this.$binding(
      "order",
      db
        .collection(
          this.item.orderType == "errand" ? "ordersErrands" : "orders"
        )
        .doc(this.item[".key"])
    );

    if (
      !this.order.paymentModifications ||
      !this.order.paymentModifications.length
    ) {
      this.order.paymentModifications = [];
    }

    this.loading = false;
  },
};
</script>

<template>
  <v-card>
    <v-snackbar top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card-title class="headline"
      >Completar transacción
      <v-chip
        v-if="item.shortId"
        :color="
          $vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)'
        "
        style="display: inline-block"
        dark
        class="d-none d-sm-block"
        :text-color="$vuetify.theme.dark ? 'white' : 'black'"
        small
        >{{ item.shortId }}</v-chip
      >
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        :loading="loading"
        fab
        small
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text style="font-size: 15px" class="mt-1">
      <div class="mt-2 d-block pl-3 pr-3" style="width: 100%">
        Ordenada de
        <b>{{ item.businessName || "Mandaditos" }}</b> por
        <b>{{ item.userName }}</b> el
        <b>{{ item.progressPlacedAtFiltered }}</b>
      </div>

      <v-container class="pa-4" fluid>
        <v-row class="align-center">
          <v-col cols="12" md="12">
            <h3>
              ¿Desea realizar la captura de
              <b style="color: #f06a25">{{ amount | currency }}</b>
              correspondiente a la orden <b>{{ item.shortId }}</b> ?
            </h3>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-row align="center" class="d-flex my-2 mx-4">
        <v-btn
          rounded
          class="flex-grow-1"
          large
          :elevation="0"
          :minHeight="50"
          :disabled="loading"
          @click="$emit('cancel')"
          :loading="loading"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          rounded
          class="flex-grow-1 ml-2 white--text"
          large
          :elevation="0"
          :minHeight="50"
          :loading="loading"
          :disabled="loading"
          @click="capturePayment"
          >Aceptar</v-btn
        >
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { db, fb } from "@/firebase";
import axios from "axios";

export default {
  name: "complete-transaction",
  props: ["item"],
  data() {
    return {
      loading: false,
      snackbarText: "",
      snackbar: false,
      amount: 0,
    };
  },
  filters: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    capturePayment() {
      this.loading = true;

      // var paymentData = {
      //   amount: this.amount,
      //   orderNumber: this.item.shortId,
      //   modificationType: 1, // Capture transaction code
      //   userId: this.item.userId,
      // };

      if (
        this.item.orderPaymentMethod == "card" &&
        this.item.orderPaymentStatus == "pendingCapture"
      ) {
        var callablePaymentGatewayCompleteTransaction = fb
          .functions()
          .httpsCallable("callablePaymentGatewayCompleteTransaction");
        callablePaymentGatewayCompleteTransaction({
          orderId: this.item[".key"],
        })
          .then((result) => {
            console.log(result);
            // this.loading = false;
            this.loading = false;
            this.$emit("success");
          })
          .catch((err) => {
            console.log(err);
            this.snackbarText = "Error al realizar la captura del pago.";
            this.snackbar = true;
            this.loading = false;
          });
        // axios({
        //   method: "POST",
        //   crossDomain: true,
        //   url: `${process.env.VUE_APP_FUNCTIONS_URL}/httpPaymentGatewayBacTransactionModification`,
        //   data: paymentData,
        // })
        //   .then((paymentResponse) => {
        //     this.updateOrderFields();
        //   })
        //   .catch((err) => {
        //     this.snackbarText = "Error al realizar la captura del pago.";
        //     this.snackbar = true;
        //     this.loading = false;
        //   });
      } else {
        this.updateOrderFields();
      }
    },
    updateOrderFields() {
      let orderPaymentStatus = "paid";
      let progressCompletedAt = new Date();

      let collection =
        this.item.orderType == "errand" || this.item.orderType == "cargo"
          ? db.collection("ordersErrands")
          : db.collection("orders");

      collection
        .doc(this.item[".key"])
        .update({
          progressCurrentStatus: "completed",
          progressCompletedAt: progressCompletedAt,
          orderPaymentStatus: orderPaymentStatus,
          progressDeliveredAt:
            this.item.orderType === "pickUp" ? progressCompletedAt : null,
        })
        .then(() => {
          this.loading = false;
          this.$emit("success");
        })
        .catch(() => {
          this.snackbarText = "Ocurrió un error al actualizar la orden";
          this.snackbar = true;
          this.loading = false;
        });
    },
  },

  mounted() {
    if (
      this.item.paymentModifications &&
      this.item.paymentModifications.length
    ) {
      let transactions = this.item.paymentModifications.find(
        (e) => e.transactionType == "refund"
      );

      this.amount = this.item.orderTotal - transactions.amount;
    } else {
      this.amount = this.item.orderTotal;
    }
  },
};
</script>

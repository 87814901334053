<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <p>Nombre del cupón</p>
        <v-text-field
          rounded
          outlined
          :disabled="order ? true : false"
          :rules="[rules.required]"
          placeholder="Nombre para identificar el cupón"
          v-model.trim="coupon.name"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <p>Código de canje</p>
        <v-text-field
          rounded
          :disabled="order ? true : false"
          :loading="generatingCode"
          placeholder="Código que usará el usuario para agregar el cupón"
          :value="coupon.code.toUpperCase()"
          @input="formatCode"
          :rules="[rules.required]"
          outlined
        >
          <template v-slot:append-outer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on" @click="generateCode()">
                  <i class="fas fa-random"></i>
                </div>
              </template>
              <span>Clic para generar un código</span>
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <p>Descripción</p>
        <v-text-field
          rounded
          placeholder="Descripción del cupón"
          v-model="coupon.description"
          outlined
        />
      </v-col>
      <v-col cols="12" md="6">
        <p>Restricciones aplican</p>
        <v-text-field
          rounded
          placeholder="Detallar las restricciones del cupón."
          v-model="coupon.disclaimer"
          outlined
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <p>Fecha que inicia</p>
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="true"
          :return-value.sync="date[0]"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              rounded
              :disabled="order ? true : false"
              :rules="[rules.required]"
              outlined
              v-on="on"
              placeholder="Fecha de inicio del cupón"
              v-model="date[0]"
            />
          </template>
          <v-date-picker
            v-model="date[0]"
            no-title
            scrollable
            @click:date="setDate1"
            :min="today"
          >
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="6">
        <p>Fecha que termina</p>
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="true"
          :return-value.sync="date[1]"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              outlined
              rounded
              :disabled="order ? true : false"
              v-on="on"
              :rules="[rules.required]"
              placeholder="Fecha que termina"
              v-model="date[1]"
            />
          </template>
          <v-date-picker
            v-model="date[1]"
            no-title
            scrollable
            @click:date="setDate2"
            :min="today"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row align="end">
      <v-col cols="12" md="6">
        <p>Tipo de cupón</p>
        <v-select
          v-model="coupon.type"
          :items="couponType"
          placeholder="Seleccione tipo de descuento"
          outlined
          :rules="[rules.required]"
          dense
          item-value="value"
          item-text="text"
          rounded
        />
      </v-col>

      <v-col cols="12" md="6">
        <p>Tipo de descuento</p>
        <v-select
          v-model="coupon.discountType"
          :items="discountType"
          placeholder="Seleccione tipo de descuento"
          outlined
          :rules="[rules.required]"
          dense
          item-value="value"
          item-text="text"
          rounded
        />
      </v-col>

      <v-col cols="12" md="6">
        <p>Monto del descuento</p>
        <v-text-field
          placeholder="Ejemplo : 25"
          rounded
          :rules="[rules.min]"
          @keydown="isNumber($event)"
          v-model.number="coupon.amount"
          outlined
        />
      </v-col>

      <v-col v-if="coupon.discountType == 'percent'" cols="12" md="6">
        <p>Monto máximo de descuento por cupón</p>
        <v-text-field
          placeholder="Monto máximo de descuento a aplicar por el cupón"
          rounded
          @keydown="isNumber($event)"
          v-model.number="coupon.maximumDiscount"
          outlined
        >
          <template v-slot:append-outer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">fas fa-question-circle</v-icon>
              </template>
              Este valor representa el máximo descuento que se podrá aplicar en
              una orden. Dejar vacío para no aplicar esta validación.
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <p>Monto mínimo de consumo</p>
        <v-text-field
          placeholder="Monto mínimo de la orden"
          rounded
          @keydown="isNumber($event)"
          v-model.number="coupon.minimumSale"
          outlined
        >
          <template v-slot:append-outer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">fas fa-question-circle</v-icon>
              </template>
              El cupón aplica si el subtotal de la orden es mayor a este valor.
              Dejar vacío para no aplicar esta validación
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <p>Cantidad disponibles para reclamar</p>
        <v-text-field
          rounded
          :rules="[rules.min]"
          @keydown="isNumber($event)"
          outlined
          :disabled="order ? true : false"
          placeholder="Cantidad disponibles para reclamar"
          v-model.number="coupon.inventory"
        />
      </v-col>

      <v-col cols="12" md="6">
        <p>Cantidad de usos por usuario</p>
        <v-text-field
          rounded
          :rules="[rules.min]"
          outlined
          :disabled="order ? true : false"
          @keydown="isNumber($event)"
          placeholder="Número de veces que el usuario podrá usar el cupón"
          v-model.number="coupon.usageAllowedByUser"
        />
      </v-col>
      <v-col cols="12" md="6">
        <p>Método de pago permitido</p>
        <v-select
          v-model="coupon.paymentGateways"
          :items="paymentGateways"
          placeholder="Seleccione los métodos de pago permitidos"
          outlined
          dense
          multiple
          item-value=".key"
          item-text="name"
          rounded
        >
          <template v-slot:append-outer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">fas fa-question-circle</v-icon>
              </template>
              Métodos de pago permitidos para canjear el cupón. No seleccione
              ningún método de pago si desea que el cupón aplique para todos.
            </v-tooltip>
          </template>
        </v-select>
      </v-col>

      <v-col cols="12" md="6">
        <p>Seleccione motivo principal de creación del cupón</p>
        <v-select
          v-model="coupon.reason"
          :items="getReason"
          placeholder="Seleccione el motivo del cupón"
          outlined
          :rules="[rules.required]"
          dense
          item-value="value"
          item-text="text"
          rounded
        >
          >
          <template v-slot:selection="data">
            {{ data.item.text }}
          </template>

          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title> {{ data.item.text }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.item.description }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </template>
          </template>
        </v-select>
      </v-col>

      <v-col v-if="order" cols="12" md="6">
        <p>Seleccione motivo específico de creación del cupón</p>
        <v-select
          v-model="secondaryReason"
          :items="allReason"
          placeholder="Seleccione el motivo"
          outlined
          @change="secondaryReasonSelected"
          :rules="[rules.required]"
          dense
          item-value=".key"
          item-text="name"
          rounded
        >
          >
          <template v-slot:selection="data">
            {{ data.item.name }}
          </template>

          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title> {{ data.item.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.item.description }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </template>
          </template>
        </v-select>
      </v-col>

      <v-col v-if="order" cols="12" md="6">
        <p>Notas adicionales</p>
        <v-textarea
          rounded
          outlined
          rows="1"
          :rules="[rules.required]"
          v-model="coupon.reasonDetails"
          label="Especificar la situación por la cuál se generó este cupón"
        ></v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import randomstring from "randomstring";
import { db } from "@/firebase";

export default {
  name: "coupon-general",
  props: ["coupon", "type", "date", "country", "order"],
  data() {
    return {
      menu1: false,
      menu2: false,
      allReason: [],
      secondaryReason: null,
      couponType: [
        { text: "Subtotal", value: "subtotal" },
        { text: "Total", value: "total" },
        { text: "Envío", value: "shipping" },
      ],

      reason: [
        {
          text: "Cupón promocional",
          value: "promotional",
          description: "Cupón creado para campañas de marketing.",
        },
        {
          text: "Cupón de crédito",
          value: "credit",
          onlySac: true,
          description:
            "Ocurrió alguna eventualidad mientras se entregaba la orden, Ocho NO paga el beneficio de este cupón.",
        },
        {
          text: "Cupón compensatorio",
          value: "compensatory",
          onlySac: true,
          description:
            "Ocurrió alguna eventualidad mientras se entregaba la orden, Ocho paga el beneficio de este cupón.",
        },
      ],
      discountType: [
        { text: "Fijo", value: "amount" },
        { text: "Porcentual", value: "percent" },
      ],
      rules: {
        required: (value) => !!value || "Obligatorio",
        min: (v) => (v && v > 0) || "Debe ingresar un valor numerico mayor a 0",
        minNegative: (v) =>
          (v && v > -1) || "Debe ingresar un valor mayor o igual a 0",
      },
      // target: [
      //   { text: "Aplica a todo", value: "none" },
      //   { text: "Aplica a comercio", value: "businesses" },
      //   { text: "Aplica a categories", value: "categories" },
      //   { text: "Aplica a usuarios", value: "users" },
      // ],
      paymentGateways: [],
      generatingCode: false,
      // couponT: {
      //   minimumSale: 0,
      //   code: "",
      // },
    };
  },
  computed: {
    today() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
    getReason() {
      return this.order ? this.reason.filter((e) => e.onlySac) : this.reason;
    },
  },
  methods: {
    secondaryReasonSelected(id) {
      let secondaryReason = this.allReason.find((e) => e[".key"] == id);

      if (secondaryReason) {
        this.coupon.secondaryReason = {
          id: secondaryReason[".key"],
          name: secondaryReason.name,
        };
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    formatCode(e) {
      // console.log(e);
      this.coupon.code = e.toUpperCase();
    },

    setDate1(date) {
      this.$refs.menu1.save(date);
    },
    setDate2(date) {
      this.$refs.menu2.save(date);
    },
    async generateCode() {
      let code = `${
        this.coupon.name.replace(/\s+/g, "").toUpperCase().substring(0, 15) ||
        "HN"
      }-${randomstring.generate(4).toUpperCase()}`;
      this.generatingCode = true;

      let result = await db
        .collection("coupons4")
        .where("code", "==", code)
        .get();
      if (result.empty) {
        this.coupon.code = code;
        this.generatingCode = false;
      } else {
        this.generateCode();
      }
    },
  },
  mounted() {
    this.$binding(
      "paymentGateways",
      db
        .collection("paymentGateways")
        .where("deleted", "==", false)
        .where("countryId", "==", this.country)
        .where("active", "==", true)
        .orderBy("name")
    );

    this.$binding(
      "allReason",
      db
        .collection("couponCreationReasons")
        .where("deleted", "==", false)
        .where("active", "==", true)
        .orderBy("name")
    );

    if (this.order) {
      this.date[0] = moment(new Date()).format("YYYY-MM-DD");
      this.date[1] = moment(new Date()).add(6, "month").format("YYYY-MM-DD");

      this.coupon.usageAllowedByUser = 1;
      this.coupon.discountType = "amount";
      this.coupon.type = "total";
      this.coupon.inventory = 1;
      this.coupon.reason = "compensatory";
    }
  },
};
</script>


<style scoped>
.divider {
  margin-top: 20px;
  margin-bottom: 20px;
}

.fa-random {
  cursor: pointer;
  /* background-color: gray; */
  /* color: white; */
  font-size: 25px;
  color: gray;
  /* padding: 10px; */
}
</style>
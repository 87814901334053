var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-snackbar',{attrs:{"top":"","timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarText))]),_c('v-overlay',{staticClass:"loading-center",attrs:{"value":_vm.saving}},[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":150,"width":150},on:{"animCreated":_vm.handleAnimation}})],1),_c('v-card',[_c('v-card-title',{staticClass:"grid-close"},[_c('span',{staticClass:"headline"},[_vm._v("Tarjetas de "+_vm._s(_vm.user.name)+" "+_vm._s(_vm.user.surname))]),_c('v-btn',{staticStyle:{"right":"10px","top":"10px"},attrs:{"icon":"","color":"primary","fab":"","absolute":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("fa-times")])],1)],1),_c('v-card-text',[_c('v-container',{staticClass:"pa-3",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{attrs:{"clearable":"","rounded":"","flat":"","filled":"","hide-details":"","label":`Buscar tarjeta`,"prepend-inner-icon":"fa-search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.getHeaders,"items":_vm.cards,"items-per-page":10,"loading":_vm.loading,"search":_vm.search,"sort-desc":true,"footer-props":{
                itemsPerPageOptions: [10, 25, 50, 100, 250],
              }},scopedSlots:_vm._u([{key:`item.createdAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("filterDate")(item.createdAt))+" ")]}},{key:`item.name`,fn:function({ item }){return [_c('p',{staticClass:"ma-0 pa-0",style:(item.deleted ? 'color:red' : '')},[_vm._v(" "+_vm._s(_vm._f("capitalize")(item.name))+" ")]),(item.deleted)?_c('small',{staticClass:"ml-2",staticStyle:{"color":"gray"}},[_vm._v("Eliminado por el usuario")]):_vm._e()]}},{key:`item.tokens.bac`,fn:function({ item }){return [_c('div',{class:{
                    'font-weight-bold': _vm.usedCard && item['.key'] == _vm.usedCard,
                  }},[_vm._v(" "+_vm._s(item.tokens.bac)+" ")]),(_vm.usedCard && item['.key'] == _vm.usedCard)?_c('small',{staticStyle:{"color":"green"}},[_vm._v("Utilizada en esta orden.")]):_vm._e()]}},{key:`item.type`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.brand)+" "+_vm._s(item.type)+" "+_vm._s(item.product)+" ")]}},{key:`item.active`,fn:function({ item }){return [_c('v-switch',{attrs:{"disabled":!_vm.isAllowed},on:{"change":function($event){return _vm.switchControlChanged(
                      { active: item.active },
                      item['.key'],
                      item
                    )}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:`item.validated`,fn:function({ item }){return [_c('v-switch',{attrs:{"disabled":!_vm.isAllowed},on:{"change":function($event){return _vm.switchControlChanged(
                      { validated: item.validated },
                      item['.key'],
                      item
                    )}},model:{value:(item.validated),callback:function ($$v) {_vm.$set(item, "validated", $$v)},expression:"item.validated"}})]}},{key:`item.deleted`,fn:function({ item }){return [_c('v-switch',{attrs:{"disabled":""},model:{value:(item.deleted),callback:function ($$v) {_vm.$set(item, "deleted", $$v)},expression:"item.deleted"}})]}},{key:`item.validThru`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("filterValidDate")(item.validThru))+" ")]}},{key:`item.options`,fn:function({ item }){return [_c('v-btn',{staticClass:"mr-3",attrs:{"small":"","color":"primary","disabled":item.deleted},on:{"click":function($event){return _vm.validateCard(item)}}},[_vm._v("Activar")]),_c('v-btn',{attrs:{"disabled":!item.location || !item.location[0],"small":"","color":"primary"},on:{"click":function($event){return _vm.viewLocation(item)}}},[_vm._v("Localización")])]}},{key:"loading",fn:function(){return [_c('div',{staticClass:"ma-5",staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","z-index":"999","width":"100%","height":"100%","display":"flex","flex-direction":"column","justify-content":"center","align-items":"center"}},[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":300,"width":400},on:{"animCreated":_vm.handleAnimation}}),_c('p',{class:_vm.$vuetify.theme.dark
                          ? 'subtitle-1 primary--text'
                          : 'subtitle-1 black--text'},[_vm._v(" Cargando Data ")])],1),_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto d-none d-md-block",attrs:{"type":"table-tbody"}})],1)]},proxy:true}],null,true)})],1)],1)],1)],1)],1),(_vm.cardLocation)?_c('v-dialog',{attrs:{"max-width":"90%"},model:{value:(_vm.modalLocation),callback:function ($$v) {_vm.modalLocation=$$v},expression:"modalLocation"}},[_c('card-location',{key:_vm.cardLocation['.key'],attrs:{"card":_vm.cardLocation},on:{"cancel":function($event){_vm.modalLocation = false}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
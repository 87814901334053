<template>
  <v-card class="px-2">
    <v-card-title>
      <span class="headline">
        {{ type == "new" ? "Nuevo" : "Editar" }} cupón
      </span>

      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>

    <v-tabs v-model="tab" align-with-title>
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab key="general"> General </v-tab>
      <v-tab key="categories"> Categorías </v-tab>
      <v-tab key="businesses"> Comercios </v-tab>
      <v-tab key="users"> Usuarios </v-tab>
      <v-tab key="cards"> Bancos </v-tab>
    </v-tabs>

    <v-card-text>
      <v-container>
        <v-snackbar top :timeout="3000" v-model="snackbar">
          {{ snackbarText }}
        </v-snackbar>
        <v-overlay class="loading-center" :value="saving">
          <lottie
            :options="defaultOptions"
            :height="300"
            :width="400"
            v-on:animCreated="handleAnimation"
          />
        </v-overlay>

        <v-tabs-items v-model="tab">
          <v-tab-item key="general">
            <v-form lazy-validation ref="coupon">
              <General
                :country="country"
                :date="date"
                :type="type"
                :coupon="coupon"
                :order="order"
              />
            </v-form>
          </v-tab-item>

          <v-tab-item key="categories">
            <!-- <h2>Categorías</h2> -->
            <br />
            <Categories :item="coupon" :city="city" />
          </v-tab-item>

          <v-tab-item key="businesses">
            <Businesses :item="coupon" :city="city" />
          </v-tab-item>

          <v-tab-item key="users">
            <Users :item="coupon" :order="order" :city="city" />
          </v-tab-item>

          <v-tab-item key="cards">
            <Cards :item="coupon" />
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-card-text>

    <v-card-actions
      ><v-row class="pa-0 ma-0">
        <v-col cols="6">
          <v-row align="center" justify="start">
            <v-switch v-model="coupon.active" label="Cupón activo"></v-switch>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row align="center" justify="end">
            <v-btn
              @click="submit"
              :loading="loading"
              class="mt-4"
              color="primary"
            >
              Guardar
            </v-btn>
          </v-row>
        </v-col>
      </v-row></v-card-actions
    >
  </v-card>
</template>

<script>
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";

import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

import Categories from "./sections/Categories";
import Businesses from "./sections/Businesses";
// import couponGeneral from "./sections/general";
import Users from "./sections/Users";

import { db, fb } from "@/firebase";
import { mapState } from "vuex";

import { validationMixin } from "vuelidate";

import { required, minValue, between } from "vuelidate/lib/validators";

import General from "./sections/general.vue";
import Cards from "./sections/creditCards.vue";

var randomstring = require("randomstring");

export default {
  name: "AddEditCoupon",
  components: {
    lottie: Lottie,
    Categories,
    Businesses,
    Users,
    General,
    Cards,
  },
  mixins: [lottieSettings, validationMixin],
  props: {
    type: {
      type: String,
      required: true,
    },
    city: {
      type: String,
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: false,
    },
    order: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      tab: null,
      loading: false,
      saving: false,
      snackbarText: "",
      snackbar: false,
      date: [],
      coupon: {
        active: true,
        amount: "",
        inventory: "",
        businesses: [],
        categories: [],
        city: "",
        claimed: 0,
        code: "",
        country: "",
        // coveredByOcho: 0,
        createdAt: new Date(),
        createdBy: "",
        deleted: false,
        description: "",
        disclaimer: "",
        from: "",
        maximumDiscount: "",
        sendPushNotification: false,
        minimumSale: "",
        name: "",
        to: "",
        type: "",
        used: 0,
        usageAllowedByUser: "",
        users: [],
        usedAt: [],
        bins: [],
        reasonDetails: "",
        secondaryReason: "",
      },
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  validations: {
    coupon: {
      inventory: { required, minValue: minValue(1) },
      name: { required },
      type: { required },
      code: { required },
      amount: {
        required,
        minValue: minValue(1),
      },
      minimumSale: { required, minValue: minValue(0) },
      maximumSale: { required, minValue: minValue(0) },
      coveredByOcho: { between: between(0, 100) },
      usageAllowedByUser: { required, minValue: minValue(1) },
      maximumDiscount: { required, minValue: minValue(1) },
      discountType: { required },
      reason: { required },
    },
  },

  methods: {
    async submit() {
      let firstValidation = this.$refs.coupon.validate();
      let secondValidation = await this.validate();
      let code = await this.validateCode();

      if (firstValidation && secondValidation && code) {
        this.loading = true;
        this.coupon.code = code.trim();
        this.coupon.from = this.generateTimestamp(this.date[0], "from");
        this.coupon.to = this.generateTimestamp(this.date[1], "to");
        this.coupon.createdAt = new Date();
        this.coupon.createdBy = this.user[".key"];
        this.coupon.country = this.country;
        this.coupon.city = this.city;
        this.coupon.isFixed =
          this.coupon.discountType == "amount" ? true : false;

        delete this.coupon.discountType;

        this.coupon.maximumDiscount = this.coupon.maximumDiscount
          ? this.coupon.maximumDiscount
          : 0;

        this.coupon.minimumSale = this.coupon.minimumSale
          ? this.coupon.minimumSale
          : 0;

        db.collection("coupons4")
          .add(this.coupon)
          .then(() => {
            this.loading = false;
            this.$emit("success", "Cupón agregado correctamente.");
          })
          .catch((err) => {
            console.log(err);
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
            this.loading = false;
          });
      }
    },

    generateTimestamp(data, fl) {
      let date = data.split("-");
      return fb.firestore.Timestamp.fromDate(
        new Date(
          parseInt(date[0]),
          parseInt(date[1]) - 1,
          parseInt(date[2]),
          parseInt(fl == "from" ? "01" : "23"),
          parseInt(fl == "from" ? "00" : "59"),
          parseInt("00")
        )
      );
    },
    async validateCode() {
      return new Promise(async (resolve, reject) => {
        if (this.coupon.code) {
          db.collection("coupons4")
            .where("code", "==", this.coupon.code)
            .get()
            .then((response) => {
              if (response.size) {
                this.showSnackBar("El código del cupón ingresado ya existe.");
                return resolve(false);
              } else {
                return resolve(this.coupon.code);
              }
            });
        } else {
          let code = await this.generateCode();

          return resolve(code);
        }
      });
    },

    validate() {
      return new Promise(async (resolve) => {
        if (!this.date[0] || !this.date[1]) {
          this.showSnackBar(
            "La fecha final debe ser mayor que la fecha inicial"
          );
          return resolve(false);
        }

        if (
          Number(this.date[0].replaceAll("-", "")) >
          Number(this.date[1].replaceAll("-", ""))
        ) {
          this.showSnackBar(
            "La fecha final debe ser mayor que la fecha inicial"
          );
          return resolve(false);
        }

        return resolve(true);
      });
    },

    showSnackBar(message) {
      this.snackbarText = message;
      this.snackbar = true;
    },

    async generateCode() {
      let code = `${
        this.coupon.name.replace(/\s+/g, "").toUpperCase().substring(0, 15) ||
        "HN"
      }-${randomstring.generate(4).toUpperCase()}`;

      let result = await db
        .collection("coupons4")
        .where("code", "==", code)
        .get();
      if (result.empty) {
        return code;
      } else {
        return this.generateCode();
      }
    },
  },

  mounted() {
    if (this.order) {
      this.coupon.name = `Crédito`;
      this.coupon.code = this.order.shortId;
      this.coupon.description = `Crédito - Orden ${this.order.shortId}`;
      this.coupon.users = [this.order.userId];
    }
  },
};
</script>

<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-card :loading="formLoading">
      <v-card-title class="headline">
        Reporte de Órdenes
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          :loading="formLoading"
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text style="font-size: 15px" class="mt-1">
        <v-container class="pa-3" fluid>
          <v-row>
            <v-col cols="12" sm="12">
              <v-select
                v-model="selectedCity"
                :items="cities"
                prepend-icon="fa-city"
                label="Seleccionar ciudad"
                item-text="name"
                item-value=".key"
                aria-autocomplete="false"
                autocomplete="off"
                hide-details
                multiple
                filled
                rounded
                :allow-overflow="false"
                clearable
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="12">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value="selectedDate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="selectedDate"
                    label="Filtrar por fecha"
                    prepend-icon="fa-calendar-alt"
                    readonly
                    filled
                    rounded
                    hide-details
                    :disabled="formLoading"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="selectedDate"
                  range
                  :title-date-format="title"
                  scrollable
                  :max="today"
                >
                  <v-row no-gutters style="flex-wrap: nowrap">
                    <v-btn
                      class="flex-grow-1 flex-shrink-0"
                      large
                      rounded
                      outlined
                      color="primary"
                      @click="modal = false"
                      >Cancelar</v-btn
                    >
                    <v-btn
                      class="flex-grow-1 flex-shrink-0"
                      large
                      rounded
                      color="primary"
                      @click="$refs.dialog.save(selectedDate)"
                    >
                      Aceptar
                    </v-btn>
                  </v-row>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <v-col cols="12" md="12">
              <v-select
                v-model="tagsToSearch"
                :items="searchableTags"
                prepend-icon="fa-filter"
                label="Filtrar por estado"
                item-text="name"
                item-value="name"
                multiple
                aria-autocomplete="false"
                autocomplete="off"
                hide-details
                filled
                rounded
                :allow-overflow="false"
                clearable
              >
                <template v-slot:selection="data">
                  <v-list-item-avatar
                    :color="data.item.color"
                    size="8"
                    class="ma-1"
                  >
                    <span class="white--text headline"></span>
                  </v-list-item-avatar>
                </template>
                <template v-slot:item="data">
                  <template v-if="checkNotObject(data)">
                    <v-list-item-content>{{ data.item }}</v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-avatar
                      :color="data.item.color"
                      size="8"
                      class="mr-2"
                    >
                      <span class="white--text headline"></span>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.name | statusFilter }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="12">
              <v-select
                v-model="selectedOrderTypes"
                :items="orderTypes"
                prepend-icon="fa-city"
                label="Seleccionar tipo de orden"
                item-text="name"
                chips
                item-value="value"
                aria-autocomplete="false"
                autocomplete="off"
                hide-details
                multiple
                filled
                rounded
                :allow-overflow="false"
                clearable
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="12">
              <v-select
                v-model="selectedPaymentMethods"
                :items="paymentMethods"
                prepend-icon="fa-city"
                label="Seleccionar por método de pago"
                item-text="name"
                chips
                item-value="value"
                aria-autocomplete="false"
                autocomplete="off"
                hide-details
                multiple
                filled
                rounded
                :allow-overflow="false"
                clearable
              >
              </v-select>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-autocomplete
                v-model="selectedBusinesses"
                :items="businesses"
                prepend-icon="fa-city"
                label="Seleccionar comercios"
                item-text="shortName"
                item-value=".key"
                aria-autocomplete="false"
                autocomplete="off"
                hide-details
                multiple
                chips
                filled
                rounded
                :allow-overflow="false"
                clearable
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-row align="center" class="d-flex mb-4 mx-4">
          <v-btn
            rounded
            class="flex-grow-1"
            large
            :elevation="0"
            :minHeight="56"
            :loading="formLoading"
            @click="$emit('cancel')"
            >Cancelar</v-btn
          >
          <v-btn
            color="primary"
            rounded
            class="flex-grow-1 ml-2 white--text"
            large
            :elevation="0"
            :minHeight="56"
            @click="generate"
            :loading="formLoading"
            >Generar</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import moment from "moment-timezone";
// import "moment/locale/es";
// moment.locale("es");
import { db, fb } from "@/firebase";
import axios from "axios";

export default {
  name: "orders-report",
  props: ["cityId", "searchableTags", "date", "cities", "tags"],
  data() {
    return {
      formLoading: true,
      snackbarText: "",
      snackbar: false,
      modal: false,
      selectedPaymentMethods: [],
      selectedCity: [],
      paymentMethods: [
        { name: "Tarjeta", value: "card" },
        { name: "Efectivo", value: "cash" },
        { name: "Tengo", value: "tengo" },
        { name: "Dilo", value: "dilo" },
        { name: "Tigo Money", value: "tigoMoney" },
      ],
      orderTypes: [
        { name: "Regular", value: "regular" },
        { name: "Pickup", value: "pickUp" },
        { name: "Mandaditos", value: "errand" },
        { name: "ATM", value: "atm" },
        { name: "Recargas Electrónicas", value: "airTime" },
      ],
      selectedOrderTypes: [],
      selectedBusinesses: [],
      businesses: [],
      selectedDate: [],
      tagsToSearch: [],
    };
  },

  computed: {
    ...mapState(["user"]),

    today() {
      return new Date().toISOString().substr(0, 10);
    },
  },

  watch: {
    selectedCity() {
      let query = db.collection("businesses").where("deleted", "==", false);

      if (this.selectedCity) {
        query = query.where("cities", "array-contains-any", this.selectedCity);
      }

      query = query.orderBy("shortName", "asc");
      this.$binding("businesses", query);

      // console.log(this.selectedCity);
    },
  },
  filters: {
    statusFilter: function (status) {
      switch (status) {
        case "placed":
          return "Colocada por el Cliente";
        case "accepted":
          return "Aceptada por el Comercio";
        case "enRoute":
          return "Chavo en Camino al Comercio";
        case "onDelivery":
          return "Chavo en Camino al Cliente";
        case "delivered":
          return "Entregada al Cliente";
        case "completed":
          return "Orden Completada";
        case "canceled":
          return "Cancelado por Cliente";
        case "pendingValidation":
          return "Requiere Validación";
        case "rejected":
          return "Cancelado por administración";
        default:
          return "Desconocido";
      }
    },
  },
  methods: {
    checkNotObject(data) {
      return typeof data.item !== "object";
    },
    title() {
      return "Selecciona las fechas a filtrar";
    },

    getSectionToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },

    async generate() {
      if (!this.selectedDate[0] || !this.selectedDate[1]) {
        this.snackbarText = "Debe seleccionar un rango de fechas.";
        this.snackbar = true;
        return;
      }
      this.formLoading = true;
      let token = await this.getSectionToken();

      if (this.selectedOrderTypes.includes("errand")) {
        this.selectedOrderTypes.push("cargo");
      }

      let data = {
        startDate: this.selectedDate[0],
        endDate: this.selectedDate[1],
        paymentMethods: this.selectedPaymentMethods,
        orderStatus: this.tagsToSearch,
        cities: this.selectedCity,
        businesses: this.selectedBusinesses,
        orderTypes: this.selectedOrderTypes,
      };

      axios
        .post(
          `${process.env.VUE_APP_FUNCTIONS_URL}/callableReportsOrders`,
          data,
          {
            headers: { Authorization: `Basic ${token}` },
            responseType: "blob",
          }
        )
        .then((response) => {
          this.formLoading = false;
          const { data, headers } = response;
          const fileName = `Reporte ${this.date[0]} al ${this.date[1]}.xlsx`;

          const blob = new Blob([data], { type: headers["content-type"] });
          let dom = document.createElement("a");
          let url = window.URL.createObjectURL(blob);
          dom.href = url;
          dom.download = decodeURI(fileName);
          dom.style.display = "none";
          document.body.appendChild(dom);
          dom.click();
          dom.parentNode.removeChild(dom);
          window.URL.revokeObjectURL(url);
          // this.$emit("cancel");
        })
        .catch((err) => {
          this.formLoading = false;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },
  },

  async mounted() {
    this.formLoading = false;

    this.selectedDate = Object.assign([], this.date);
    this.tagsToSearch = Object.assign([], this.tags);

    if (this.cityId) {
      this.selectedCity = this.cityId;
    }
    // console.log(this.cityId, this.status, this.date);

    // console.log(this.user);
  },
};
</script>
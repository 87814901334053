<template>
  <v-card :loading="loading" :disabled="loading" class="py-1">
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card-title class="justify-center px-3">
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        small
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
      <span class="headline text-center">
        ¿Seguro que desea
        {{
          process == "generateTask"
            ? "asignar la orden a un motociclista"
            : `aceptar la orden`
        }}
        <b v-if="!process && editedItem.shortId"> {{ editedItem.shortId }} </b>?
        <div class="mt-2 d-block text-center caption" style="width: 100%">
          Ordenada de
          <b>{{ editedItem.businessName || "MANDADITOS" }}</b>
          <br />por
          <b>{{ editedItem.userName }}</b>
          {{ editedItem.progressPlacedAtRelative }}
        </div>

        <div
          v-if="editedItem.orderType == 'pickUp'"
          class="mt-2 d-block text-center caption"
          style="width: 100%"
        >
          <b class="primary--text title">
            ¡El cliente recogerá la orden en el comercio!
          </b>
        </div>
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text style="height: auto; overflow-x: hidden">
      <v-row
        v-if="
          editedItem.orderType == 'regular' ||
          editedItem.orderType == 'errand' ||
          editedItem.orderType == 'cargo'
        "
        align="center"
        class="mx-0 ma-0 pa-0"
      >
        <v-col cols="12">
          <v-autocomplete
            v-model="agent"
            :disabled="loading"
            :items="sortedAgents"
            height="55px"
            filled
            return-object
            solo
            flat
            rounded
            hide-details
            :background-color="
              $vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)'
            "
            color="primary"
            chips
            label="¿Que chavo realizará esta entrega?"
            item-text="name"
            :item-disabled="availableAgents"
            class="text-center"
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                class="font-weight-bold"
                style="text-transform: capitalize"
                :input-value="data.selected"
                color="primary"
              >
                <v-avatar left>
                  <v-img :src="data.item.fleet_thumb_image"></v-img>
                </v-avatar>
                {{ data.item.name }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template v-if="checkNotObject(data)">
                <v-list-item-content
                  v-text="data.item.trim()"
                ></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar>
                  <img :src="data.item.fleet_thumb_image" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    style="text-transform: capitalize"
                    v-html="data.item.name.trim()"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="data.item.transport_desc"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-text-field
            rounded
            height="55"
            v-model.number="processTime"
            @keypress="isNumber($event)"
            label="Tiempo de elaboración (Minutos)"
            filled
          />
        </v-col>
      </v-row>
      <v-col cols="12" v-if="agent">
        <b class="red--text title"> ¡Esta acción no se puede revertir! </b>
        <br />
        el sistema asignará a
        <b class="text--primary">{{ agent.name }}</b>
        para realizar entrega.
      </v-col>
      <v-col
        cols="12"
        v-if="
          editedItem.orderType == 'regular' &&
          !agent &&
          process != 'generateTask'
        "
        class="text-center"
      >
        <b class="red--text title"> ¡Esta acción no se puede revertir! </b>
        <br />
        el sistema auto-asignará a un motorista para su entrega.
      </v-col>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-row align="center" class="d-flex my-1 mx-4">
        <v-btn
          rounded
          class="flex-grow-1"
          large
          :elevation="0"
          :minHeight="56"
          :disabled="loading"
          @click="$emit('cancel')"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          rounded
          class="flex-grow-1 ml-2"
          large
          :elevation="0"
          :minHeight="56"
          :disabled="loading"
          @click="acceptOrder()"
        >
          {{ process == "generateTask" ? "Asignar" : "Aceptar" }}
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { db, fb } from "@/firebase";

export default {
  name: "accept-order",
  props: ["item", "process"],
  data() {
    return {
      loading: true,
      snackbarText: "",
      snackbar: false,
      editedItem: {},
      agents: [],
      agent: "",
      processTime: 25,
    };
  },

  computed: {
    ...mapState(["user"]),
    sortedAgents() {
      return this.agents.sort((a, b) =>
        a.is_available < b.is_available
          ? 1
          : a.is_available === b.is_available
          ? a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : -1
          : -1
      );
    },
  },
  methods: {
    isNumber: function (evt, value) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (value && !Number.isInteger(value) && charCode == 46) {
        evt.preventDefault();
      }
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    async acceptOrder() {
      this.loading = true;
      let reference = db.collection(
        this.editedItem.orderType == "errand" ||
          this.editedItem.orderType == "cargo"
          ? "ordersErrands"
          : "orders"
      );

      reference
        .doc(this.editedItem[".key"])
        .update({
          fleetId: this.agent ? this.agent.fleet_id : null,
          progressCurrentStatus: "accepted",
          progressAcceptedAt: new Date(),
          processTime: this.processTime ? this.processTime : null,
          acceptedBy: this.user[".key"],
        })
        .then(() => {
          this.loading = false;
          this.$emit("success");
        })
        .catch(() => {
          this.snackbar = true;
          this.snackbarText = `Error al aceptar Orden ${this.editedItem.shortId} 😢`;
          this.loading = false;
        });
    },
    availableAgents(item) {
      return item.is_available === 0 ? true : false;
    },
    checkNotObject(data) {
      return typeof data.item !== "object";
    },
  },

  async mounted() {
    this.editedItem = JSON.parse(JSON.stringify(this.item));
    this.loading = true;

    var httpTookanGetDrivers = fb
      .functions()
      .httpsCallable("httpTookanGetDrivers");
    httpTookanGetDrivers({})
      .then((result) => {
        this.agents = result.data.agents;
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        this.snackbar = true;
        this.snackbarText = "Imposible obtener lista de chavos disponibles.";
      });
  },
};
</script>

<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-card class="py-1 pa-3">
      <v-card-title>
        Cobro/Reembolso de la orden <b>{{ item.shortId }}</b>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row align="center" class="mx-0 ma-0 pa-0">
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              @keypress="isNumber"
              rounded
              outlined
              label="Monto"
              :rules="[rules.required, rules.number]"
              v-model.number="amount"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-select
              rounded
              outlined
              :rules="[rules.required]"
              label="Tipo de transacción"
              :items="getItems"
              v-model="selectedType"
            >
            </v-select>
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <v-textarea
              label="Motivo de la modificación"
              rounded
              v-model="reason"
              :rules="[rules.required]"
              outlined
              rows="3"
            ></v-textarea>
          </v-col>
          <v-col
            v-if="selectedType == 'refund'"
            cols="12"
            class="mt-0 pt-0"
            sm="12"
            md="12"
          >
            <v-alert color="red lighten-1" dark>
              Atención : Se realizará la reversión del monto ingresado y la
              captura del resto de la transacción. Esta acción no se puede
              revertir y solo es posible realizarla una vez.
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-row align="center" class="d-flex mb-4 mx-4">
          <v-btn
            rounded
            class="flex-grow-1"
            large
            :elevation="0"
            :minHeight="56"
            :loading="loading"
            :disabled="loading"
            @click="$emit('cancel')"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            rounded
            class="flex-grow-1 ml-2"
            large
            :elevation="0"
            @click="next"
            :minHeight="56"
            :loading="loading"
            :disabled="loading"
          >
            Aceptar
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { fb } from "@/firebase";
import { mapState } from "vuex";

export default {
  name: "add-extra-charge",
  props: ["item"],
  data() {
    return {
      loading: true,
      snackbarText: "",
      snackbar: false,
      amount: "",
      selectedType: "",
      reason: "",
      modificationType: [
        { text: "Cobro extra", value: "payment" },
        { text: "Reembolso", value: "refund" },
      ],
      rules: {
        required: (value) => !!value || "Obligatorio",
        number: (value) => (!!value && value > 0) || "Debe ingresar un número",
        min: (v) => (v && v.length >= 8) || "Mínimo 8 caracteres",
      },
    };
  },

  computed: {
    ...mapState(["user"]),

    getItems() {
      if (this.item.transactionCompleted) {
        return [{ text: "Cobro extra", value: "payment" }];
      } else {
        return [
          { text: "Cobro extra", value: "payment" },
          { text: "Reembolso", value: "refund" },
        ];
      }
    },
  },
  methods: {
    isNumber: function (evt, value) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (value && !Number.isInteger(value) && charCode == 46) {
        evt.preventDefault();
      }
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    next() {
      if (
        this.amount &&
        !isNaN(this.amount) &&
        this.selectedType &&
        this.reason
      ) {
        if (this.selectedType == "payment" && this.amount < 10) {
          this.snackbarText =
            "Los cobros extras deben ser montos superiores a 10 Lempiras.";
          this.snackbar = true;
          return;
        }

        this.loading = true;

        let data = {
          orderId: this.item[".key"],
          reason: this.reason,
          amount: this.amount,
          selectedType: this.selectedType,
          orderType: this.item.orderType,
        };

        var callableOrdersPaymentModification = fb
          .functions()
          .httpsCallable("callableOrdersPaymentModification");
        callableOrdersPaymentModification(data)
          .then((result) => {
            this.loading = false;
            this.$emit("success");
          })
          .catch((error) => {
            this.loading = false;
            this.snackbarText = error.message;
            this.snackbar = true;
          });
      } else {
        this.snackbarText = "Complete todos los campos";
        this.snackbar = true;
      }
    },
  },

  mounted() {
    this.loading = false;
  },
};
</script>

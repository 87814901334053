<template>
  <v-card>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card-title class="headline"
      >Mostrar/ocultar columnas
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        absolute
        small
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text style="font-size: 15px" class="mt-1">

      <div style="width: 100%" v-for="(item, id) of headers" :key="id">
        <v-checkbox
          color="primary"
          v-model="item.active"
          :label="item.text || item.label"
        >
        </v-checkbox>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
   
        <v-btn
          color="primary"
          rounded
          block
          large
          @click="updateLocalHeaders"
          :loading="loading"
          :elevation="0"
          :minHeight="50"
          >Guardar Cambios</v-btn
        >
  
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "show-hide-columns",
  props: ["item", "headers"],
  data() {
    return {
      loading: false,
      snackbarText: "",
      snackbar: false,
    };
  },

  methods: {
    updateLocalHeaders() {
      this.$store.commit("setOrderHeaders", this.headers);
      this.$emit("success");
    },
  },
};
</script>

<template>
  <v-container class="pa-0 ma-0" fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-card class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline"
          >Historial de Cambios en {{ editedItem.shortId }}</span
        >
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-0 ma-0" fluid>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" sm="12" md="12">
              <v-data-table
                :headers="headers"
                :items="list"
                :items-per-page="10"
                sort-by="createdAt"
                :sort-desc="true"
                :single-expand="true"
                :expanded.sync="expanded"
                show-expand
                item-key="id"
                :footer-props="{
                  itemsPerPageOptions: [10, 50, 100],
                }"
              >
                <template v-slot:[`item.createdAt`]="{ item }">
                  <span class>{{ item.createdAt | filterDate }}</span>
                </template>
                <template v-slot:expanded-item="{ item }">
                  <!-- {{ item }} -->
                  <td :colspan="3" class="pa-4">
                    <v-card
                      class="text-left px-1 mx-1"
                      :color="
                        $vuetify.theme.dark
                          ? 'rgba(0, 0, 0, 0.4)'
                          : 'rgba(0, 0, 0, 0.1)'
                      "
                      elevation="0"
                      min-width="250"
                      style="border-radius: 17px !important; overflow: hidden"
                    >
                      <v-card-text class="text--primary">
                        <!-- <p class="ml-5 font-weight-bold">Descripción</p>
                        <p class="ml-7">{{ item.reason }}</p> -->

                        <p class="ml-5 font-weight-bold">Valores modificados</p>
                        <v-row class="ml-5">
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                            class="font-weight-bold"
                          >
                            Parámetro
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                            class="font-weight-bold"
                          >
                            Valor anterior
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                            class="font-weight-bold"
                          >
                            Nuevo valor
                          </v-col>
                        </v-row>

                        <v-row
                          class="ml-5"
                          v-if="
                            item.oldProductsSubTotal != item.newProductsSubTotal
                          "
                        >
                          <v-col cols="12" sm="4" md="4">
                            Subtotal de productos
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            {{ item.oldProductsSubTotal | currency }}
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            {{ item.newProductsSubTotal | currency }}
                          </v-col>
                        </v-row>

                        <v-row
                          class="ml-5"
                          v-if="item.oldDeliveryCost != item.newDeliveryCost"
                        >
                          <v-col cols="12" sm="4" md="4">
                            Costo por delivery
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            {{ item.oldDeliveryCost | currency }}
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            {{ item.newDeliveryCost | currency }}
                          </v-col>
                        </v-row>

                        <v-row
                          class="ml-5"
                          v-if="item.oldOrderDiscount != item.newOrderDiscount"
                        >
                          <v-col cols="12" sm="4" md="4">
                            Descuento por cupón
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            {{ item.oldOrderDiscount | currency }}
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            {{ item.newOrderDiscount | currency }}
                          </v-col>
                        </v-row>

                        <v-row
                          class="ml-5"
                          v-if="item.oldOrderTip != item.newOrderTip"
                        >
                          <v-col cols="12" sm="4" md="4"> Propina </v-col>
                          <v-col cols="12" sm="4" md="4">
                            {{ item.oldOrderTip | currency }}
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            {{ item.newOrderTip | currency }}
                          </v-col>
                        </v-row>

                        <v-row
                          class="ml-5"
                          v-if="item.oldOrderTotal != item.newOrderTotal"
                        >
                          <v-col cols="12" sm="4" md="4">
                            Total de la Orden
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            {{ item.oldOrderTotal | currency }}
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            {{ item.newOrderTotal | currency }}
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </td>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="pa-0 ma-0">
            <v-col cols="12">
              <v-row justify="end">
                <v-btn
                  @click="$emit('cancel')"
                  class="save-btn"
                  color="primary"
                >
                  Cerrar
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
// import { db, fb } from "@/firebase";
// import { mapActions } from "vuex";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
  name: "order-modification-history",
  props: ["editedItem"],

  data() {
    return {
      loading: true,
      snackbar: false,
      snackbarText: "",
      expanded: [],
      list: [],
      headers: [
        {
          value: "createdAt",
          text: "Fecha",
        },
        {
          value: "reason",
          text: "Descripción",
        },
        {
          value: "userName",
          text: "Modificado por",
        },
      ],
    };
  },
  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("LLL");
      // return  moment.unix(timestamp).format("MM/DD/YYYY")
    },
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },
  },

  mounted() {
    this.list = this.editedItem.modificationReasons;
    this.list = this.list.map((item, index) => {
      let reason = item;
      reason.id = index;
      return reason;
    });
  },
};
</script>
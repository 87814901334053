<template>
  <v-row>
    <v-col cols="12" sm="11" md="11">
      <v-select
        v-model="selectedCategory"
        :items="sortedCategories"
        placeholder="Escoge la categoría"
        outlined
        item-text="name"
        @change="getBusinesses"
        item-value=".key"
        rounded
      />
    </v-col>

    <v-col v-if="loading" cols="12" sm="12">
      <v-row>
        <v-col cols="12" md="6">
          <v-skeleton-loader
            ref="skeleton"
            type="image"
            class="mx-auto d-none d-md-block mt-5"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="6">
          <v-skeleton-loader
            ref="skeleton"
            type="image"
            class="mx-auto d-none d-md-block mt-5"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-col>

    <v-col v-else cols="12" sm="12" md="12">
      <a-transfer
        :listStyle="{
          height: '400px!important',
          width: '45%',
          background: 'white',
        }"
        :data-source="businesses"
        :titles="['Todas las opciones', 'Opciones vinculadas']"
        :target-keys="optionsSelected"
        :selected-keys="selectedKeys"
        :show-search="true"
        @search="handleSearch"
        :render="(item) => item.title"
        :filter-option="filterOption"
        @change="handleChange"
        @selectChange="handleSelectChange"
        :locale="{
          itemUnit: '',
          itemsUnit: '',
          notFoundContent: 'Lista vacía',
          searchPlaceholder: 'Buscar sección',
        }"
      />
    </v-col>
  </v-row>
</template>

<script>
import transfer from "ant-design-vue/lib/transfer";
import { db } from "@/firebase";

export default {
  props: ["item", "city"],
  components: {
    "a-transfer": transfer,
  },
  data() {
    return {
      businesses: [],
      selectedKeys: [],
      optionsSelected: [],
      loading: true,
      categories: [],
      selectedCategory: "",
    };
  },

  computed: {
    sortedCategories() {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      return this.categories.sort(compare);
    },

    sortedBusinesses() {
      function compare(a, b) {
        if (a.title < b.title) return -1;
        if (a.title > b.title) return 1;
        return 0;
      }

      return this.businesses.sort(compare);
    },
  },

  methods: {
    handleChange(nextTargetKeys) {
      this.item.businesses = nextTargetKeys;
      this.optionsSelected = nextTargetKeys;
    },
    handleSearch(value) {
      return value.toUpperCase();
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
    },
    filterOption(inputValue, option) {
      return option.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;
    },
    getBusinesses(categoryId) {
      if (categoryId) {
        this.loading = true;
        this.$binding(
          "businesses",
          db
            .collection("businesses")
            // .where("city", "==", this.city)
            .where("deleted", "==", false)
            .where("ochoCategories", "array-contains", categoryId)
        ).then((businesses) => {
          this.businesses = businesses.map((item) => {
            return {
              title: item.shortName ? item.shortName : "",
              key: item[".key"],
            };
          });

          this.businesses = this.businesses.filter((item) => !item.isFather);
          this.businesses = this.sortedBusinesses;
          this.loading = false;
        });
      }
    },
  },
  async mounted() {
    this.$binding(
      "categories",
      db
        .collection("categories")
        .where("city", "==", this.city)
        .where("deleted", "==", false)
    ).then((resp) => {
      this.loading = false;
    });
  },
};
</script>

<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-card>
      <v-card-title class="headline">
        <span class="headline">
          Modificar Orden
          <v-chip
            v-if="item.shortId"
            class="d-none md-block"
            :color="
              $vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)'
            "
            style="display: inline-block"
            dark
            :text-color="$vuetify.theme.dark ? 'white' : 'black'"
            small
            >{{ item.shortId }}</v-chip
          >
        </span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          :loading="loading"
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text style="font-size: 15px" class="mt-1">
        <v-container class="pa-3 pt-0 ">
          <v-row align="center" class="pl-2 pr-2">
            <v-chip
              :color="getColor(item.progressCurrentStatus)"
              small
              dark
              style="text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2)"
              >{{ item.progressCurrentStatus | statusFilter }}</v-chip
            >
            <div class="grey--text ml-1">
              {{ editedItem.progressPlacedAtRelative }}
            </div>
            <div class="mt-2 d-block" style="width: 100%">
              Ordenada de
              <b>{{ editedItem.businessName || "Mandaditos" }}</b> por
              <b>{{ editedItem.userName || editedItem.clientName }}</b>
            </div>
          </v-row>
          <v-row class="mt-5">
            <v-col cols="12" sm="12">
              <v-select
                v-model="editedItem.progressCurrentStatus"
                :items="orderStatus"
                prepend-icon="fa-city"
                label="Seleccionar estado"
                aria-autocomplete="false"
                autocomplete="off"
                hide-details
                :loading="loading"
                filled
                rounded
                :allow-overflow="false"
                clearable
              >
              </v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row align="center" class="mb-4 mx-4">
          <v-btn
            color="primary"
            rounded
            block
            large
            @click="updateOrderStatus"
            :loading="loading"
            :elevation="0"
            :minHeight="56"
            >Guardar Cambios</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// import { mapActions, mapState } from "vuex";
// import moment from "moment-timezone";
// import "moment/locale/es";
// moment.locale("es");
import { db, fb } from "@/firebase";

export default {
  name: "change-status",
  props: ["item", "searchableTags"],
  data() {
    return {
      loading: false,
      snackbarText: "",
      snackbar: false,
      editedItem: {},
      orderStatus: [
        { value: "enRoute", text: "Chavo en Camino al Comercio" },
        { value: "onDelivery", text: "Chavo en Camino al Cliente" },
        { value: "delivered", text: "Entregada al Cliente" },
      ],
    };
  },

  filters: {
    statusFilter: function (status) {
      switch (status) {
        case "placed":
          return "Colocada por el Cliente";
        case "accepted":
          return "Aceptada por el Comercio";
        case "enRoute":
          return "Chavo en Camino al Comercio";
        case "onDelivery":
          return "Chavo en Camino al Cliente";
        case "delivered":
          return "Entregada al Cliente";
        case "completed":
          return "Orden Completada";
        case "canceled":
          return "Cancelado por Cliente";
        case "pendingValidation":
          return "Requiere Validación";
        case "rejected":
          return "Cancelado por administración";
        default:
          return "Desconocido";
      }
    },
  },

  // computed: {
  //   ...mapState(["user"]),
  // },
  methods: {
    getColor(status) {
      const result = this.searchableTags.find(({ name }) => name === status);
      return result ? result.color : "gray";
    },
    updateOrderStatus() {
      this.loading = true;

      let data = {
        progressLastModifiedAt: new Date(),
        progressCurrentStatus: this.editedItem.progressCurrentStatus,
      };
      switch (this.editedItem.progressCurrentStatus) {
        case "enRoute":
          data.progressPickupStartedAt = new Date();
          break;
        case "onDelivery":
          data.progressDeliveryStartedAt = new Date();
          break;
        case "delivered":
          data.progressDeliveredAt = new Date();
          break;
      }

      let collection =
        this.item.orderType == "errand" || this.item.orderType == "cargo"
          ? db.collection("ordersErrands")
          : db.collection("orders");
      collection
        .doc(this.editedItem[".key"])
        .update(data)
        .then(() => {
          this.loading = false;
          this.$emit("success");
        })
        .catch((err) => {
          this.snackbar = true;
          this.snackbarText = `Error al modificar Orden ${this.editedItem.shortId} 😢`;
          this.loading = false;
        });
    },
  },
  mounted() {
    this.editedItem = JSON.parse(JSON.stringify(this.item));
  },
};
</script>

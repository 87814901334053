<template>
  <v-card :loading="formLoading">
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card-title class="headline">
      Confirmar acción
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        small
        :loading="formLoading"
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text
      style="height: auto; overflow-x: hidden; font-size: 13px"
      class="mt-1"
    >
      <v-row class="px-4">
        <v-col cols="12">
          <p class="text-justify">
            <b>¿Está seguro que desea rechazar la siguiente orden?</b>
          </p>
        </v-col>
        <v-col cols="6" md="6">
          <span class="font-weight-bold">ID de la orden</span>
          <p>{{ itemToCancel.shortId }}</p>
        </v-col>
        <v-col cols="6" md="6">
          <span class="font-weight-bold">Total de la orden</span>
          <p>{{ itemToCancel.orderTotal | currency }}</p>
        </v-col>
        <v-col cols="6" md="6">
          <span class="font-weight-bold">Nombre del comercio</span>
          <p>
            {{
              itemToCancel.orderType == "errand" ||
              itemToCancel.orderType == "cargo"
                ? "MANDADITO"
                : itemToCancel.businessName
            }}
          </p>
        </v-col>
        <v-col cols="6" md="6">
          <span class="font-weight-bold">Nombre del cliente</span>
          <p>{{ itemToCancel.userName }}</p>
        </v-col>
      </v-row>
      <v-row class="px-4">
        <v-col cols="12" sm="12" md="12">
          <v-autocomplete
            v-model="itemToCancel.rejectedReason"
            :items="reasons"
            rounded
            outlined
            label="Motivo de cancelación de la orden"
            item-text="name"
            item-value=".key"
          >
            <template v-slot:selection="data">
              {{ data.item.name }}
            </template>
            <template v-slot:item="data">
              <template>
                <!-- <v-list-item-avatar>
                  <img :src="data.item.avatar" />
                </v-list-item-avatar> -->
                <v-list-item-content>
                  <v-list-item-title> {{ data.item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    data.item.description
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
          <!-- <v-textarea
            rounded
            v-model="itemToCancel.rejectedReason"
            filled
            hide-details
            placeholder="¿Motivo de cancelación de la orden?"
            rows="3"
          >
          </v-textarea> -->
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-row align="center" class="d-flex my-1 mx-4">
        <v-btn
          rounded
          class="flex-grow-1"
          large
          :elevation="0"
          :minHeight="40"
          :loading="formLoading"
          @click="$emit('cancel')"
          >Cancelar</v-btn
        >
        <v-btn
          color="red darken-4"
          rounded
          class="flex-grow-1 ml-2 white--text"
          large
          :elevation="0"
          :minHeight="40"
          :loading="formLoading"
          @click="canceledConfirmed()"
          >Aceptar</v-btn
        >
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { db, fb } from "@/firebase";
import axios from "axios";

export default {
  name: "cancel-order",
  props: ["itemToCancel"],
  data() {
    return {
      formLoading: false,
      snackbarText: "",
      snackbar: false,
      reasons: [],
    };
  },
  filters: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    reverseOrder() {
      this.formLoading = true;
      var paymentData = {
        amount: this.itemToCancel.orderTotal,
        orderNumber: this.itemToCancel.shortId,
        modificationType: 3,
        userId: this.itemToCancel.userId,
      };

      axios({
        method: "POST",
        crossDomain: true,
        url: `${process.env.VUE_APP_FUNCTIONS_URL}/callablePaymentGatewayFacTesterTransactionModification`,
        data: paymentData,
      })
        .then((response) => {
          // this.updateOrderFields();
          console.log(response);
          this.formLoading = true;
          this.snackbarText = "Orden reversada";
          this.snackbar = true;
        })
        .catch((err) => {
          this.snackbarText = "Error al realizar la captura del pago.";
          this.snackbar = true;
          this.loading = false;
        });
    },

    canceledConfirmed() {
      let reason = this.reasons.find(e => e['.key'] == this.itemToCancel.rejectedReason);

   
      if (this.itemToCancel.rejectedReason) {
        this.formLoading = true;

        let data = {
          progressCurrentStatus: "rejected",
          progressRejectedAt: new Date(),
          progressLastModifiedAt: new Date(),
          rejectedReasonId: this.itemToCancel.rejectedReason,
          rejectedReason: reason.name,
          rejectedBy: {
            id: this.user[".key"],
            name: this.user.name,
          },
        };

        let collection =
          this.itemToCancel.orderType == "errand" ||
          this.itemToCancel.orderType == "cargo"
            ? db.collection("ordersErrands")
            : db.collection("orders");

        collection
          .doc(this.itemToCancel[".key"])
          .update(data)
          .then(() => {
            this.formLoading = false;
            this.$emit("success");
          })
          .catch(() => {
            this.formLoading = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      } else {
        this.snackbarText = "Ingrese el motivo de cancelación de la orden.";
        this.snackbar = true;
      }
    },
  },

  async mounted() {
    await this.$binding(
      "reasons",
      db
        .collection("cancellationReasons")
        .where("active", "==", true)
        .where("deleted", "==", false)
        .orderBy("name", "asc")
    );
  },
};
</script>
<template>
  <v-card>
    <v-card-title class="headline">
      Estructurar campos
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row class="mt-5">
        <v-col cols="12" sm="4" md="4">
          <span class="font-weight-bold"> Campos de usuario </span>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" sm="8" md="8">
          <span class="font-weight-bold">
            Columnas en el archivo seleccionado
          </span>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row v-for="(item, index) of requiredFields" :key="index">
        <v-col cols="12" sm="4" md="4">
          <p class="mt-5">{{ item.name }}</p>
        </v-col>
        <v-col cols="12" sm="8" md="8">
          <v-select
            v-model="item.selectedColumn"
            :items="fileColumns"
            label="Seleccionar columna correspondiente"
            item-text="text"
            item-value="value"
            aria-autocomplete="false"
            filled
            :loading="loading"
            rounded
            :allow-overflow="false"
            clearable
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions style="justify-content: center">
      <v-row no-gutters style="flex-wrap: nowrap">
        <v-btn
          class="flex-grow-1 ma-3"
          large
          rounded
          outlined
          color="primary"
          :loading="loading"
          @click="$emit('cancel')"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="flex-grow-1 ma-3"
          @click="success"
          large
          rounded
          :loading="loading"
          color="primary"
        >
          Aceptar
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import readXlsxFile from "read-excel-file";

export default {
  props: ["file"],
  data() {
    return {
      requiredFields: [
        {
          name: "Nombre",
          value: "name",
          selectedColumn: 0,
        },
        {
          name: "Número de teléfono",
          value: "phone",
          selectedColumn: 1,
        },
      ],
      fileColumns: null,
      loading: false,
    };
  },

  methods: {
    success() {
      this.loading = true;
      this.$emit("success", this.requiredFields);
    },
  },

  mounted() {
    readXlsxFile(this.file, { sheet: 1 }).then((rows) => {
      this.fileColumns = rows[0].map((item, index) => {
        if (item) {
          return {
            value: index,
            text: item,
          };
        }
      });

      this.fileColumns.filter((item) => item != "undefined" || item);
      this.loading = false;
    });
  },
};
</script>

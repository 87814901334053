<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline"
          >Tarjetas de {{ user.name }} {{ user.surname }}</span
        >
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-3" fluid>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model="search"
                clearable
                rounded
                flat
                filled
                hide-details
                :label="`Buscar tarjeta`"
                prepend-inner-icon="fa-search"
              />
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-data-table
                :headers="getHeaders"
                :items="cards"
                :items-per-page="10"
                :loading="loading"
                :search="search"
                :sort-desc="true"
                :footer-props="{
                  itemsPerPageOptions: [10, 25, 50, 100, 250],
                }"
              >
                <template v-slot:[`item.createdAt`]="{ item }">
                  {{ item.createdAt | filterDate }}
                </template>

                <template v-slot:[`item.name`]="{ item }">
                  <p :style="item.deleted ? 'color:red' : ''" class="ma-0 pa-0">
                    {{ item.name | capitalize }}
                  </p>

                  <small class="ml-2" style="color: gray" v-if="item.deleted"
                    >Eliminado por el usuario</small
                  >
                </template>

                <template v-slot:[`item.tokens.bac`]="{ item }">
                  <div
                    :class="{
                      'font-weight-bold': usedCard && item['.key'] == usedCard,
                    }"
                  >
                    {{ item.tokens.bac }}
                  </div>
                  <small
                    style="color: green"
                    v-if="usedCard && item['.key'] == usedCard"
                    >Utilizada en esta orden.</small
                  >
                </template>

                <template v-slot:[`item.type`]="{ item }">
                  {{ item.brand }} {{ item.type }} {{ item.product }}
                </template>

                <template v-slot:[`item.active`]="{ item }">
                  <v-switch
                    :disabled="!isAllowed"
                    @change="
                      switchControlChanged(
                        { active: item.active },
                        item['.key'],
                        item
                      )
                    "
                    v-model="item.active"
                  ></v-switch>
                </template>

                <template v-slot:[`item.validated`]="{ item }">
                  <v-switch
                    :disabled="!isAllowed"
                    @change="
                      switchControlChanged(
                        { validated: item.validated },
                        item['.key'],
                        item
                      )
                    "
                    v-model="item.validated"
                  ></v-switch>
                </template>

                <template v-slot:[`item.deleted`]="{ item }">
                  <v-switch disabled v-model="item.deleted"></v-switch>
                </template>

                <template v-slot:[`item.validThru`]="{ item }">
                  {{ item.validThru | filterValidDate }}
                </template>

                <template v-slot:[`item.options`]="{ item }">
                  <v-btn
                    @click="validateCard(item)"
                    small
                    color="primary"
                    :disabled="item.deleted"
                    class="mr-3"
                    >Activar</v-btn
                  >
                  <v-btn
                    :disabled="!item.location || !item.location[0]"
                    @click="viewLocation(item)"
                    small
                    color="primary"
                    >Localización</v-btn
                  >
                </template>

                <template v-slot:loading>
                  <div class="ma-5" style="position: relative">
                    <div
                      style="
                        position: absolute;
                        z-index: 999;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <lottie
                        :options="defaultOptions"
                        :height="300"
                        :width="400"
                        v-on:animCreated="handleAnimation"
                      />
                      <p
                        :class="
                          $vuetify.theme.dark
                            ? 'subtitle-1 primary--text'
                            : 'subtitle-1 black--text'
                        "
                      >
                        Cargando Data
                      </p>
                    </div>
                    <v-skeleton-loader
                      ref="skeleton"
                      type="table-tbody"
                      class="mx-auto d-none d-md-block"
                    ></v-skeleton-loader>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog v-if="cardLocation" v-model="modalLocation" max-width="90%">
      <card-location
        @cancel="modalLocation = false"
        :key="cardLocation['.key']"
        :card="cardLocation"
      ></card-location>
    </v-dialog>
  </div>
</template>

<script>
import { db } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
// import { mapState } from "vuex";
import cardLocation from "./card-location";

export default {
  name: "edit-user-cards",
  props: ["user", "usedCard"],
  components: {
    lottie: Lottie,
    cardLocation,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },

      snackbar: false,
      snackbarText: "",
      saving: false,
      cards: [],
      search: "",
      cardLocation: null,
      modalLocation: false,
      loading: true,
      headers: [
        {
          text: "Nombre",
          value: "name",
          width: "200px",
        },
        {
          text: "Token",
          value: "tokens.bac",
        },
        {
          text: "Banco Emisor",
          value: "issuer",
          width: "300px",
        },
        {
          text: "Creación",
          value: "createdAt",
        },

        {
          text: "Tipo",
          value: "type",
          width: "300px",
        },

        {
          text: "Terminación",
          value: "endsIn",
        },

        {
          text: "Válida hasta",
          value: "validThru",
        },
        {
          text: "Tarjeta activa",
          value: "active",
        },
        {
          text: "Tarjeta validada",
          value: "validated",
        },
        {
          value: "options",
          width: "300px",
          roles: ["sudo", "admin", "AF"],
        },
      ],
    };
  },
  filters: {
    filterValidDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("MM/YYYY");
    },
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("L");
    },
    filterGender(gender) {
      let list = {
        male: { text: "Masculino" },
        female: { text: "Femenino" },
        other2: { text: "Otro" },
      };

      return list[gender].text;
    },

    capitalize(value) {
      let arr = value.split(" ");
      let name = "";
      arr.forEach((letter) => {
        name +=
          " " + letter.charAt(0).toUpperCase() + letter.slice(1).toLowerCase();
      });
      return name;
    },
  },
  mounted() {
    db.collection("users")
      .doc(this.user[".key"])
      .collection("cards")
      .orderBy("deleted")
      .get()
      .then((data) => {
        data.forEach((item) => {
          let card = item.data();
          card[".key"] = item.id;
          this.cards.push(card);
        });
        this.loading = false;
      });
  },

  computed: {
    // ...mapState(["user"]),
    isAllowed() {
      let allowedRoles = ["sudo", "admin", "AF"];
      return allowedRoles.includes(this.$store.state.user.type);
    },

    getHeaders() {
      return this.headers.filter(
        (item) =>
          !item.roles || (item.roles && item.roles.includes(this.user.type))
      );
    },
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    viewLocation(item) {
      this.cardLocation = item;
      this.modalLocation = true;
    },
    validateCard(card) {
      this.saving = true;
      db.collection("users")
        .doc(this.user[".key"])
        .collection("cards")
        .doc(card[".key"])
        .update({
          active: true,
          validated: true,
        })
        .then((ref) => {
          this.saving = false;
          this.snackbarText = "Tarjeta activa";
          this.snackbar = true;
        })
        .catch((err) => {
          this.saving = false;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },

    switchControlChanged(data, cardId, item) {
      if (data && cardId) {
        this.saving = true;
        db.collection("users")
          .doc(this.user[".key"])
          .collection("cards")
          .doc(cardId)
          .update(data)
          .then((ref) => {
            this.saving = false;
            this.snackbarText =
              "Información de tarjeta actualizada correctamente.";
            this.snackbar = true;
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;

            if (data.hasOwnProperty("active")) item.active = !item.active;
            else if (data.hasOwnProperty("validated"))
              item.validated = !item.validated;
          });
      }
    },
  },
};
</script>
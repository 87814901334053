<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-row v-if="!order">
      <v-col cols="12">
        <h2>Ingrese el número celular del cliente</h2>
        <p class="grey--text">
          Asegurate que sea el mismo número que registró en ocho
        </p>
      </v-col>
      <v-col cols="12" md="2">
        <v-autocomplete
          v-model="countryCode"
          :items="codes"
          outlined
          rounded
          class="mt-0 mt-md-2"
        />
      </v-col>
      <v-col cols="12" md="4">
        <input
          v-on:keyup.enter="searchUser"
          :loading="loading"
          :disabled="loading"
          class="control-input control-input-number"
          type="number"
          placeholder="Ingrese el número de celular"
          v-model.number="phone"
        />
      </v-col>

      <v-col cols="12" md="3">
        <v-btn
          :loading="loading"
          @click="searchUser()"
          block
          class="save-btn mt-4"
          color="primary"
        >
          Buscar
        </v-btn>
      </v-col>

      <v-col cols="12" md="3">
        <v-btn
          @click="openFileExplorer"
          :loading="loading"
          block
          class="save-btn mt-4"
          color="primary"
        >
          Cargar excel
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <p class="title ma-0" style="color: #f06a25">Clientes agregados</p>
        <v-divider></v-divider>
        <v-checkbox
          v-model="item.sendPushNotification"
          v-if="users && users.length"
          hide-details
          label="Envíar una push notification a cada usuario."
        ></v-checkbox>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="users"
          :items-per-page="10"
          :loading="loading"
          :sort-desc="true"
          item-key=".key"
          :single-expand="true"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:[`item.name`]="{ item }">
            {{ item.name }} {{ item.surname }}
          </template>
          <template v-slot:[`item.options`]="{ item }">
            <v-btn
              v-if="!order"
              @click="removeUser(item)"
              small
              class="ml-3 white--text"
              color="red darken-4"
            >
              Eliminar
            </v-btn>
          </template>
        </v-data-table>
        <input
          @change="setExcelFile"
          ref="input"
          id="input"
          class="input"
          accept=".xlsx"
          type="file"
        />
      </v-col>
    </v-row>

    <v-dialog
      max-width="550"
      v-model="matchDialog"
      v-if="matchDialog"
      persistent
    >
      <match-fields
        @cancel="matchDialog = false"
        :file="file"
        @success="processFile"
      />
    </v-dialog>

    <v-dialog
      max-width="550"
      v-model="modalConfirm"
      persistent
      v-if="user.length > 0"
    >
      <v-card>
        <v-card-title class="headline">
          Resultado de la búsqueda
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalConfirm = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p class="title ma-0 mt-5" style="color: #f06a25">Nombre</p>
          <span class="font-weight-bold" style="font-size: 16px">
            {{ user[0].name }} {{ user[0].surname }}
          </span>
          <p class="title ma-0 mt-5" style="color: #f06a25">
            Correo electrónico
          </p>
          <span class="font-weight-bold" style="font-size: 16px">
            {{ user[0].email }}
          </span>
          <p class="title ma-0 mt-5" style="color: #f06a25">
            Número de teléfono
          </p>
          <span class="font-weight-bold" style="font-size: 16px">
            {{ user[0].phone }}
          </span>
        </v-card-text>

        <v-card-actions style="justify-content: center">
          <v-row no-gutters style="flex-wrap: nowrap">
            <v-btn
              class="flex-grow-1 ma-3"
              large
              rounded
              outlined
              color="primary"
              @click="modalConfirm = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="flex-grow-1 ma-3"
              large
              rounded
              color="primary"
              @click="addUser()"
            >
              Agregar
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import matchFields from "./match-fields";
import readXlsxFile from "read-excel-file";

export default {
  props: ["type", "item", "order"],
  components: {
    matchFields,
  },
  data() {
    return {
      user: [],
      headers: [
        { text: "Nombre", value: "name" },
        { text: "Celular", value: "phone" },
        { text: "Correo electrónico", value: "email" },
        { value: "options", align: "end" },
      ],
      // userNotFound: false,
      // userRepeated: false,
      // sendPushNotification: false,
      snackbar: false,
      snackbarText: "",
      phone: "",
      loading: false,
      countryCode: "+504",
      codes: ["+504", "+502"],
      users: [],
      modalConfirm: false,
      matchDialog: false,
      file: null,
    };
  },
  methods: {
    addUser() {
      this.users.unshift(this.user[0]);
      this.phone = "";
      this.user = [];
      this.modalConfirm = false;

      let result = this.users.map((item) => {
        return item[".key"];
      });

      this.item.users = result;
    },
    openFileExplorer() {
      this.file = null;
      this.$refs.input.click();
    },
    processFile(e) {
      let phone = e.find((item) => item.value == "phone");
      let phonePosition = phone.selectedColumn;
      this.users = [];

      readXlsxFile(this.file, { sheet: 1 }).then(async (rows) => {
        for (let i = 0; i < rows.length; i++) {
          const user = rows[i];
          let userPhone = user[phonePosition]
            ? user[phonePosition].toString()
            : null;

          if (userPhone) {
            userPhone = userPhone.startsWith("504")
              ? `+${userPhone}`
              : userPhone.startsWith("+504")
              ? userPhone
              : `+504${userPhone}`;

            let userResult = await db
              .collection("users")
              .where("phone", "==", userPhone)
              .limit(1)
              .get();

            if (!userResult.empty) {
              let user = userResult.docs[0].data();
              user[".key"] = userResult.docs[0].id;
              this.users.unshift(user);
            }
          }
        }
        this.matchDialog = false;
        document.getElementById("input").value = "";
        let result = this.users.map((item) => {
          return item[".key"];
        });

        this.item.users = result;
      });
    },
    setExcelFile(e) {
      if (e.target.files[0]) {
        this.file = e.target.files[0];
        this.matchDialog = true;
      }
    },
    removeUser(item) {
      this.users = this.users.filter((user) => user[".key"] != item[".key"]);

      let result = this.users.map((item) => {
        return item[".key"];
      });

      this.item.users = result;
    },
    async searchUser() {
      if (this.phone) {
        this.loading = true;
        let user = this.$binding(
          "user",
          db
            .collection("users")
            .where("phone", "==", `${this.countryCode}${this.phone}`)
        ).then((user) => {
          this.loading = false;

          if (user.length == 0) {
            this.snackbarText = `El número de teléfono ${this.countryCode}${this.phone} no se ha encontrado.`;
            this.snackbar = true;
          } else {
            let addedUser = this.users.find(
              (user) => user[".key"] == this.user[0][".key"]
            );

            if (addedUser) {
              this.snackbarText = `El número ${this.countryCode}${this.phone} ya está agregado en la lista.`;
              this.snackbar = true;
            } else {
              this.modalConfirm = true;
            }
          }
        });

        await Promise.all([user]);
      }
    },
  },
  mounted() {
    let arrayCopy = Object.assign([], this.item.users);

    arrayCopy.forEach(() => {
      db.collection(`users`)
        .where(fb.firestore.FieldPath.documentId(), "in", arrayCopy)
        .get()
        .then((users) => {
          this.loading = false;
          users.forEach((user) => {
            let item = Object.assign({ ".key": user.id }, user.data());
            this.users.push(item);
          });
        });
    });
  },
};
</script>

<style scoped>
.control-input {
  border-color: rgba(117, 117, 117, 0.582);
}

.input {
  display: none;
}
</style>
